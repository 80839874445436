<template>
    <div class="container">
        <van-loading class="loading" color="#9d1d22" text-color="#9d1d22" v-if="loading" size="24px">加载中...</van-loading>

        <div v-if="loading == false">
            <van-sticky>
                <van-nav-bar :title="title" left-arrow @click-left="goBack()">
                </van-nav-bar>
            </van-sticky>
            <div v-if="roleType == 0"> {{ roleErrorMessage }}</div>
            <h2 class="van-doc-demo-block__title">{{ this.dealer.name }}
                活动信息</h2>
            <van-form @submit="onSubmit" ref="campaignForm" @failed="onFailed">
                <van-cell-group inset class="van-cell-group--inset">
                    <van-field v-model="campaign.date" is-link readonly label="活动日期" placeholder="点击选择日期" required
                        :rules="[{ required: true, message: '请填写活动日期' }]" @click="showDatePicker = true" />

                    <van-popup v-model="showDatePicker" position="bottom" :style="{ height: '30%' }">
                        <van-datetime-picker @cancel="showDatePicker = false" @confirm="onConfirmDate"
                            v-model="DatePickerDate" type="date" title="活动日期" />
                    </van-popup>

                    <van-field label="活动地点" v-model="campaign.address" required
                        :rules="[{ required: true, message: '请填写活动地点' }]" />
                    <van-field label="活动类型">
                        <template #input>
                            <van-radio-group v-model="typeName" direction="horizontal">
                                <van-radio name="品鉴会">品鉴会</van-radio>
                                <van-radio name="返厂游">返厂游</van-radio>
                                <van-radio name="日常销售">日常销售</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field label="活动地点分类">
                        <template #input>
                            <van-radio-group v-model="addressTypeName" direction="horizontal">
                                <van-radio name="当地酒店">当地酒店</van-radio>
                                <van-radio name="名酒体验中心">名酒体验中心</van-radio>
                                <van-radio name="客户会所">客户会所</van-radio>
                                <van-radio name="酒厂">酒厂</van-radio>
                                <van-radio name="其他">其他</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>

                    <van-field label="品鉴版本" required :rules="[{ required: true, message: '请选择类型' }]">
                        <template #input>
                            <van-radio-group v-model="version" direction="horizontal" @change="ChangeVersion">
                                <van-radio name="仪式调酒版">仪式调酒版</van-radio>
                                <van-radio name="盲品版">盲品版</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>

                    <van-field v-show="showversionremark" label="盲品酒样" v-model="campaign.versionRemark"
                        required="showversionremark" :rules="versionremark" />

                    <van-field label="品鉴会类型" required :rules="[{ required: true, message: '请选择类型' }]">
                        <template #input>
                            <van-radio-group v-model="goal" direction="horizontal">
                                <van-radio name="品鉴动销型">品鉴动销型</van-radio>
                                <van-radio name="意见领袖培育型">意见领袖培育型</van-radio>
                                <van-radio name="终端培育型">终端培育型</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>

                </van-cell-group>
                <h2 class="van-doc-demo-block__title">参与人员</h2>
                <van-cell-group inset class="van-cell-group--inset">
                    <van-field label="邀约总人数" v-model="campaign.inviteCount" />
                    <van-field label="到场总人数" v-model="getComeCount" readonly @click="comeCountClick" class="readonly" />
                    <van-field label="工作人员数" v-model="campaign.employeeCount" />
                    <van-field label="经销商人数" v-model="campaign.dealerCustomerCount" />

                    <van-field label="销售姓名" v-model="campaign.mainSale" />
                    <van-field label="品鉴师姓名" v-model="campaign.helpSale" />
                    <h2 class="van-doc-demo-block__title">终端店</h2>
                    <van-cell-group inset class="van-cell-group--inset">
                        <div style="margin: 16px; text-align: center;">
                            <van-button plain type="info" round size="small"
                                @click.prevent="showAddCustomer">添加终端店</van-button>
                        </div>

                        <table class="basic-table">
                            <thead>
                                <tr>
                                    <th>终端</th>
                                    <th>类型</th>
                                    <th>人数</th>
                                    <th>朋友数</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in filteredCustomerList" :key="index">
                                    <td>{{ item.name }}</td>
                                    <td>{{ getCustemerType(item.typeId) }}</td>
                                    <td>{{ item.count }}</td>
                                    <td>{{ item.customerCount }}</td>
                                    <td>
                                        <van-button icon="cross" plain type="info" round size="mini"
                                            @click.prevent="deleteCustomer(index)">删</van-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </van-cell-group>
                </van-cell-group>

                <h2 class="van-doc-demo-block__title">活动费用</h2>
                <van-cell-group inset class="van-cell-group--inset">


                    <van-field label="总费用" class="readonly" v-model="getTotalCost" readonly @click="comeCountClick2" />

                    <van-field label="伴手礼" v-model="campaign.gift" />
                    <van-field label="伴手礼费用" v-model="campaign.giftCost" />
                    <van-field label="餐费" v-model="campaign.footCost" />
                    <van-field label="用酒费用" class="readonly" v-model="getWineCost" readonly @click="comeCountClick2" />
                    <div style="margin: 16px; text-align: center;">
                        <van-button plain type="info" round size="small" @click.prevent="showWineList">添加用酒</van-button>
                    </div>
                    <table class="basic-table">
                        <thead>
                            <tr>
                                <th>产品</th>
                                <th>规格</th>
                                <th>价格</th>
                                <th>数量</th>
                                <th>金额</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredWineList" :key="index">
                                <td>{{ item.product }}</td>
                                <td>{{ item.specs }}</td>
                                <td>{{ item.productPrice }}</td>
                                <td>{{ item.count }}</td>
                                <td>{{ item.productPrice * item.count }}</td>
                                <td>
                                    <!-- <van-button plain type="info" round size="mini"
                                        @click.prevent="deleteWineItem(index)">删除</van-button>  -->
                                    <van-button icon="cross" plain type="info" round size="mini"
                                        @click.prevent="deleteWineItem(index)">删</van-button>

                                </td>
                            </tr>
                        </tbody>
                    </table>

                </van-cell-group>



                <h2 class="van-doc-demo-block__title">动销产品</h2>
                <h2 class="van-doc-demo-block__warnning" v-if="roleType == 2">动销产品，请联系和您合作的品鉴师管理</h2>

                <van-cell-group inset class="van-cell-group--inset">
                    <div style="margin: 16px; text-align: center;">
                        <van-button plain type="info" round size="small" @click.prevent="showAddSales()">添加动销</van-button>
                    </div>
                    <table class="basic-table">
                        <thead>
                            <tr>
                                <th>终端</th>
                                <th>类型</th>
                                <th>动销产品</th>
                                <th>规格</th>
                                <th>数量</th>
                                <th>总金额</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredOrderList" :key="index">
                                <td>{{ item.customerName }}</td>
                                <td>{{ getOrderType(item.typeId) }}</td>
                                <td>{{ item.product }}</td>
                                <td>{{ item.specs }}</td>
                                <td>{{ item.count }}</td>
                                <td>{{ item.amount }}</td>
                                <td>
                                    <van-button icon="cross" plain type="info" round size="mini"
                                        @click.prevent="deleteSales(index)">删</van-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </van-cell-group>



                <div v-if="roleType != 3">
                    <h2 class="van-doc-demo-block__title">销售人员总结</h2>
                    <van-cell-group inset class="van-cell-group--inset">

                        <van-field name="uploader" label="品鉴图片">
                            <template #input>
                                <van-uploader v-model="fileList" multiple :max-count="9" :after-read="afterReadFile" />
                            </template>
                        </van-field>
                        <van-field label="总结内容" v-model="campaign.remark" rows="3" autosize type="textarea"
                            placeholder="请输总结内容" />

                    </van-cell-group>
                </div>

                <div v-if="roleType != 2">
                    <h2 class="van-doc-demo-block__title">品鉴师总结</h2>
                    <van-cell-group inset class="van-cell-group--inset">

                        <van-field name="uploader" label="品鉴图片">
                            <template #input>
                                <van-uploader v-model="fileList2" multiple :max-count="9" :after-read="afterReadFile2" />
                            </template>
                        </van-field>
                        <van-field label="总结内容" v-model="campaignremark2" rows="3" autosize type="textarea"
                            placeholder="请输总结内容" />

                    </van-cell-group>
                </div>
                <div style="margin: 16px;" v-show="roleType == 1 || roleType == 99">
                    <van-button round block type="primary" @click.prevent="goBack">
                        返回
                    </van-button>
                </div>

                <div style="margin: 16px;" v-show="roleType == 0">
                    <div> {{ roleErrorMessage }}</div>
                    <van-button round block type="primary" @click.prevent="goBack">
                        返回
                    </van-button>
                </div>
                <div style="margin: 16px;" v-show="(roleType > 1 && roleType < 10) || roleType == 999">
                    <van-button round block type="primary" native-type="submit">
                        提交
                    </van-button>
                    <div style="margin: 16px; text-align: center;">
                        <van-button plain type="info" round size="small" @click.prevent="copyToClipboard">复制内容</van-button>
                    </div>

                </div>

            </van-form>

            <!-- 选择用酒 -->
            <van-popup v-model="winepopupVisible" closeable position="bottom" :style="{ height: '90%' }">
                <van-nav-bar title="选择用酒" />
                <van-cell-group>
                    <van-cell v-for="(wine, index) in systemWineList" :key="index" :title="wine.name"
                        :label="'规格：' + wine.specs + '   单价：' + wine.price">
                        <template #right-icon>
                            <van-stepper v-model="wine.count" :min="0" theme="round" button-size="22" />
                        </template>
                    </van-cell>
                </van-cell-group>
                <div style="margin: 16px; text-align: center;">
                    <van-button type="info" round block @click.prevent="addWine">确认</van-button>
                </div>
            </van-popup>

            <!-- 添加终端 -->
            <van-popup v-model="customerpopupVisible" closeable position="bottom" :style="{ height: '90%' }">
                <van-form @submit="addCustomer">
                    <van-nav-bar title="添加终端" />
                    <van-field v-model="newCustomer.name" label="终端" placeholder="请输入终端名称" required
                        :rules="[{ required: true, message: '请填终端' }]" />
                    <van-field label="类型" required :rules="[{ required: true, message: '请选择类型' }]">
                        <template #input>
                            <van-radio-group v-model="newCustomer.typeId" direction="horizontal">
                                <van-radio :name="1">烟酒店</van-radio>
                                <van-radio :name="2">连锁商超</van-radio>
                                <van-radio :name="3">团购商</van-radio>
                                <van-radio :name="4">会所老板</van-radio>
                                <van-radio :name="5">酒业同行</van-radio>
                                <van-radio :name="6">其它</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>

                    <van-field label="终端人数" required :rules="[{ required: true, message: '终端人数' }]">
                        <template #input>
                            <van-stepper v-model="newCustomer.count" :min="0" theme="round" button-size="22" />
                        </template>
                    </van-field>


                    <van-field label="朋友数" required :rules="[{ required: true, message: '请输入朋友数' }]">
                        <template #input>
                            <van-stepper v-model="newCustomer.customerCount" :min="0" theme="round" button-size="22" />
                        </template>
                    </van-field>
                    <van-field v-model="newCustomer.remark" label="朋友类型" placeholder="请输入朋友类型" />

                    <!-- <van-field v-model="newCustomer.customerCount" label="朋友数" placeholder="请输入朋友数"  required
                        :rules="[{ required: true, message: '请选择类型' }]" /> -->

                    <div style="margin: 16px; text-align: center;">
                        <van-button type="info" round block>确认添加</van-button>
                    </div>
                </van-form>
            </van-popup>

            <!-- 添加动销 -->
            <van-popup v-model="salesDialogVisible" closeable position="bottom" :style="{ height: '90%' }">
                <van-nav-bar title="添加动销" />
                <van-cell-group>
                    <van-cell>
                        <van-field readonly clickable name="newCustomername" :value="newSale.customerName" label="终端"
                            placeholder="点击选择终端" @click="showNewCoustmerNamePicker = true" />
                        <van-popup v-model="showNewCoustmerNamePicker" position="bottom">
                            <van-picker show-toolbar :columns="filteredCousterNameList" @confirm="onConfirmNewCoustmerName"
                                @cancel="showNewCoustmerNamePicker = false" />
                        </van-popup>


                        <van-field label="类型">
                            <template #input>
                                <van-radio-group v-model="newSale.typeId" direction="horizontal">
                                    <van-radio :name="1">当日动销</van-radio>
                                    <van-radio :name="2">7日动销</van-radio>
                                </van-radio-group>
                            </template>
                        </van-field>
                        <van-nav-bar title="动销产品" />
                        <van-cell-group>
                            <van-cell v-for="(wine, index) in orderWineList" :key="index" :title="wine.name"
                                :label="'规格：' + wine.specs + '   单价：' + wine.price">
                                <template #right-icon>
                                    <van-stepper v-model="wine.count" :min="0" theme="round" button-size="22" />
                                </template>
                            </van-cell>
                        </van-cell-group>


                    </van-cell>
                </van-cell-group>
                <div style="margin-top: 16px; text-align: center;">
                    <van-button type="info" round block @click.prevent="addSales">确认添加</van-button>
                </div>
            </van-popup>

        </div>
    </div>
</template>
  
<script>
import { GetDealer, GetDealerCampaign, AddDealerCampaign, UpdateDealerCampaign, GetSystemProduct, GetRoleType } from "@/api/dealer";
import { uploadfileplus } from "@/api/file";
import { Toast } from "vant";



//import { GetExamQuestionForUser, AddAnswer } from "@/api/exam";

//import { uploadfileplus } from "@/api/file";

export default {
    data() {
        return {
            show: false,
            loading: false,
            showDatePicker: false,
            showNewCoustmerNamePicker: false,
            showOrderProductSelect: false,
            DatePickerDate: new Date(),
            title: "新增活动",
            dealer: {},
            fileList: [],
            fileList2: [],
            typeName: "",
            addressTypeName: "",
            isEdit: false,
            campaign: {
                id: "",
                name: "",
                date: "",
                address: "",
                typeId: 1,
                addressTypeId: 1,
                inviteCount: 0,
                comeCount: 0,
                employeeCount: 0,
                dealerCustomerCount: 0,
                mainSale: "",
                mainSaleId: "",
                helpSale: "",
                helpSaleId: "",
                totalCost: 0,
                footCost: 0,
                giftCost: 0,
                wineCost: 0,
                imageUrl: "",
                remark: "",
                remark2: "",
                status: 0,
                dealerId: "",
                wineList: [],
                customerList: [],
                orderList: []

            },
            campaignremark2:"",
            winepopupVisible: false,
            systemWineList: [],//后台
            orderWineList: [],//后台
            customerpopupVisible: false,
            newCustomer: {
                name: '',
                typeId: '',
                count: 0,
                customerCount: 0,
                remark: "",
                amount: ''
            },
            salesDialogVisible: false,
            currentCustomer: {
                sales: []
            },
            saleList: [],
            newSale: {
                typeId: 1,
                product: "",
                productPrice: 0,
                specs: "箱",
                amount: 0,
                count: 0,
            },
            roleType: 0,
            roleErrorMessage: "",
            version: "",
            showversionremark: false,
            goal: "",


        };
    },

    created() {
        this.initData();
    },
    computed: {
        versionremark(){
            if(this.showversionremark)
            {
                return [{ required: true, message: '请填写品鉴版本' }];
                
            }
            else
            {
                return[];
            }

        },
        filteredWineList() {
            return this.campaign.wineList.filter(contact => !contact.isDelete);
        },
        filteredCustomerList() {
            return this.campaign.customerList.filter(contact => !contact.isDelete);
        },
        filteredOrderList() {
            return this.campaign.orderList.filter(contact => !contact.isDelete);
        },
        filteredCousterNameList() {
            const filteredList = this.campaign.customerList.filter(contact => !contact.isDelete);

            var names = filteredList.map(item => item.name);
            if (names.length > 0) {
                names.unshift('无');
            }
            else {
                names = ['无']
            }
            return names;
        },

        getTotalAmount(item) {
            const filteredList = this.campaign.orderList.filter(contact => !contact.isDelete && contact.customername == item.name);

            const sum = filteredList.reduce((accumulator, item) => accumulator + item.amount, 0);
            return sum;
        },
        //计算到场客户
        getComeCount() {
            var sum1 = this.filteredCustomerList.reduce(function (total, obj) {
                return total + obj.count;
            }, 0);
            var sum2 = this.filteredCustomerList.reduce(function (total, obj) {
                return total + obj.customerCount;
            }, 0);

            var sum3 = this.campaign.employeeCount;
            var sum4 = this.campaign.dealerCustomerCount;

            if (isNaN(sum1)) {
                sum1 = 0
            }
            if (isNaN(sum2)) {
                sum2 = 0
            }
            if (isNaN(sum3)) {
                sum3 = 0
            }
            if (isNaN(sum4)) {
                sum4 = 0
            }

            return Number(sum1) + Number(sum2) + Number(sum3) + Number(sum4);
        },
        //总费用
        getTotalCost() {
            var sum1 = this.filteredWineList.reduce(function (total, obj) {
                return total + obj.productPrice * obj.count;
            }, 0);
            var sum2 = this.campaign.giftCost;
            var sum3 = this.campaign.footCost;
            if (isNaN(sum1)) {
                sum1 = 0
            }
            if (isNaN(sum2)) {
                sum2 = 0
            }
            if (isNaN(sum3)) {
                sum3 = 0
            }
            return Number(sum1) + Number(sum2) + Number(sum3)
        },
        //用酒费用
        getWineCost() {
            var sum1 = this.filteredWineList.reduce(function (total, obj) {
                return total + obj.productPrice * obj.count;
            }, 0);

            return Number(sum1);
        }
    },
    methods: {

        ChangeVersion(option) {
            if (option == "盲品版") {
                this.showversionremark = true;
            }
            else {
                this.showversionremark = false;
            }
        },
        onFailed(failed) {
            const errors = failed.errors;
            this.$refs.campaignForm.scrollToField(errors[0].name, true);
        },
        getCustemerType(id) {
            var name = ""
            switch (id) {
                case 1:
                    name = "烟酒店";
                    break;
                case 2:
                    name = "连锁超时";
                    break;
                case 3:
                    name = "团购商";
                    break;
                case 4:
                    name = "会所老板";
                    break;
                case 5:
                    name = "酒业同行";
                    break;
                case 6:
                    name = "其他";
                    break;
                default:
                    break;
            }
            return name;

        },
        getOrderType(id) {
            var name = ""
            switch (id) {
                case 1:
                    name = "当日动销";
                    break;
                case 2:
                    name = "7日动销";
                    break;
                default:
                    break;
            }
            return name;

        },
        comeCountClick() {
            Toast('到场总人数只读，到场人数根据 工作人员数，和终端数计算得出');
        },
        comeCountClick2() {
            Toast('只读列，计算得出');
        },
        handleInput(value) {
            // 处理输入事件
            this.newCustomer.name = value;
        },
        handleSelect(value) {
            // 处理选择事件
            this.newCustomer.name = value;
        },
        onConfirmNewCoustmerName(value) {
            this.newSale.customerName = value;
            this.showNewCoustmerNamePicker = false;
        },
        async initData() {

            this.campaign= {
                id: "",
                name: "",
                date: "",
                address: "",
                typeId: 1,
                addressTypeId: 1,
                inviteCount: 0,
                comeCount: 0,
                employeeCount: 0,
                dealerCustomerCount: 0,
                mainSale: "",
                mainSaleId: "",
                helpSale: "",
                helpSaleId: "",
                totalCost: 0,
                footCost: 0,
                giftCost: 0,
                wineCost: 0,
                imageUrl: "",
                remark: "",
                status: 0,
                dealerId: "",
                wineList: [],
                customerList: [],
                orderList: []

            };
            this.campaignremark2="";


            this.typeName = "";
            this.addressTypeName = "";
            let dealerId = this.$route.query.dealerId;

            let roleResult = await GetRoleType();
            if (roleResult.data.success) {
                this.roleType = roleResult.data.data;
            }
            else {
                this.roleType = 0;
                this.roleErrorMessage = roleResult.data.errorMessage;
            }

            let id = this.$route.query.id;
            if (id) {
                this.isEdit = true
                let result = await GetDealerCampaign(id);
                if (result.data.success) {
                    this.campaign = Object.assign(this.campaign, result.data.data)
                    this.title = "完善活动";
                    this.typeName = this.campaign.typeName;
                    this.addressTypeName = this.campaign.addressTypeName
                    this.version = this.campaign.version;
                    this.goal = this.campaign.goal;
                    this.campaignremark2=this.campaign.remark2;
                }
            }


            if (dealerId) {
                this.campaign.dealerId = dealerId;
            }
            else {
                dealerId = this.campaign.dealerId;
            }

            let dresult = await GetDealer(dealerId);
            if (dresult.data.success) {
                this.dealer = dresult.data.data;
            }


            this.fileList = [];
            if (this.campaign.imageUrl != "" && this.campaign.imageUrl != undefined) {
                let urls = this.campaign.imageUrl.split(" ");
                urls.forEach((url) => {
                    this.fileList.push({
                        url: url,
                        imageFit: "contain",
                        previewSize: 80,
                    });
                });
            }
            this.fileList2 = [];
            if (this.campaign.imageUrl2 != "" && this.campaign.imageUrl2 != undefined) {
                let urls = this.campaign.imageUrl2.split(" ");
                urls.forEach((url) => {
                    this.fileList2.push({
                        url: url,
                        imageFit: "contain",
                        previewSize: 80,
                    });
                });
            }

        },
        onConfirmDate() {
            Date.prototype.Format = function (fmt) {
                //author: meizz
                var o = {
                    "M+": this.getMonth() + 1, //月份
                    "d+": this.getDate(), //日
                    "h+": this.getHours(), //小时
                    "m+": this.getMinutes(), //分
                    "s+": this.getSeconds(), //秒
                    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
                    S: this.getMilliseconds(), //毫秒
                };
                if (/(y+)/.test(fmt))
                    fmt = fmt.replace(
                        RegExp.$1,
                        (this.getFullYear() + "").substr(4 - RegExp.$1.length)
                    );
                for (var k in o)
                    if (new RegExp("(" + k + ")").test(fmt))
                        fmt = fmt.replace(
                            RegExp.$1,
                            RegExp.$1.length == 1
                                ? o[k]
                                : ("00" + o[k]).substr(("" + o[k]).length)
                        );
                return fmt;
            };

            this.campaign.date = this.DatePickerDate.Format("yyyy-MM-dd"),
                this.showDatePicker = false;

        },
        async onSubmit() {
            var result = null;

            //上传图片
            let urls = "";
            this.fileList.forEach(element => {
                if (element.url != undefined) {
                    urls += element.url + " "
                }
            });
            let urls2 = "";
            this.fileList2.forEach(element => {
                if (element.url != undefined) {
                    urls2 += element.url + " "
                }
            });


            this.campaign.imageUrl = urls.trimEnd();
            this.campaign.imageUrl2 = urls2.trimEnd();
            this.campaign.comeCount = this.getComeCount;
            this.campaign.totalCost = this.getTotalCost;
            this.campaign.wineCost=this.getWineCost;
            if(this.campaign.dealerCustomerCount==""){
                this.campaign.dealerCustomerCount=0;
            }

            if(this.campaign.inviteCount==""){
                this.campaign.inviteCount=0;
            }
            if(this.campaign.comeCount==""){
                this.campaign.comeCount=0;
            }
            if(this.campaign.employeeCount==""){
                this.campaign.employeeCount=0;
            }
            if(this.campaign.giftCost==""){
                this.campaign.giftCost=0;
            }
            
            this.campaign.typeName = this.typeName;
            this.campaign.addressTypeName = this.addressTypeName;
            this.campaign.version = this.version;
            this.campaign.goal = this.goal;
            this.campaign.remark2=this.campaignremark2;

            if (this.campaign.id == undefined || this.campaign.id == "") {
                result = await AddDealerCampaign(this.campaign)
            }
            else {
                result = await UpdateDealerCampaign(this.campaign)
            }

            if (result.data.success) {
                Toast("提交成功");
                this.$router.replace({ path: '/dealer/dealercampaign', query: { para: this.campaign.dealerId }, })
            } else {
                Toast(result.data.errorMessage);
            }
        },

        goBack() {
            if (this.$route.query.back) {
                this.$router.back();
            }
            else {
                this.$router.replace({ path: '/dealer/dealercampaign', query: { para: this.dealer.id }, })
            }
        },
        afterReadFile: async function (files) {
            Toast("文件上传中");
            files.status = 'uploading';
            files.message = '上传中...';
            
            let formData = new FormData();
            formData.append("file", files.file);
            let aresult = await uploadfileplus(formData, "dealer");

            let url = aresult.data; // Get url from response
            this.fileList =
                this.fileList.filter(function (item) {
                    return item.url != undefined && item.url.indexOf("http") >= 0
                });
            this.fileList.push({
                url: url,
                imageFit: "contain",
                previewSize: 80,
            });

            Toast("文件上传成功");
        },
        afterReadFile2: async function (files) {
            Toast("文件上传中");
            files.status = 'uploading';
            files.message = '上传中...';
            let formData = new FormData();
            formData.append("file", files.file);
            let aresult = await uploadfileplus(formData, "dealer");

            let url = aresult.data; // Get url from response
            this.fileList2 =
                this.fileList2.filter(function (item) {
                    return item.url != undefined && item.url.indexOf("http") >= 0
                });
            this.fileList2.push({
                url: url,
                imageFit: "contain",
                previewSize: 80,
            });

            Toast("文件上传成功");
        },


        deleteWineItem(index) {
            const contact = this.filteredWineList[index];
            const contactIndex = this.campaign.wineList.findIndex(c => c === contact);
            if (contactIndex !== -1) {
                this.campaign.wineList[contactIndex].isDelete = true;
            }



        },
        async showWineList() {

            let presult = await GetSystemProduct({
                current: 1,
                pageSize: 100,
                sorterType: 0,
                filterData: {
                    sourceId: 1,
                }
            });
            if (presult.data.success) {

                this.systemWineList = presult.data.data;
            }

            this.winepopupVisible = true;
        },
        addWine() {
            const selectedWines = this.systemWineList.filter((wine) => wine.count > 0);
            selectedWines.forEach((wine) => {
                const newItem = {
                    product: wine.name,
                    specs: wine.specs,
                    productPrice: wine.price, // 从接口获取的价格
                    count: wine.count,
                    isDelete: false,
                };
                this.campaign.wineList.push(newItem);
            });

            this.winepopupVisible = false;
        },

        deleteCustomer(index) {
            const contact = this.filteredCustomerList[index];
            const contactIndex = this.campaign.customerList.findIndex(c => c === contact);
            if (contactIndex !== -1) {
                this.campaign.customerList[contactIndex].isDelete = true;
            }
        },

        showAddCustomer() {
            this.customerpopupVisible = true;
        },
        addCustomer() {
            const newCustomer = { ...this.newCustomer, isDelete: false };
            this.campaign.customerList.push(newCustomer);
            this.customerpopupVisible = false;
            this.newCustomer = {
                name: '',
                typeId: '',
                count: 0,
                customerCount: 0,
                remark: "",
                amount: ''
            };
        },

        deleteSales(index) {


            if (this.roleType != 3 && this.roleType != 4 && this.roleType != 999) {
                Toast("请联系和您合作的品鉴师管理动销")
                return;
            }

            const contact = this.filteredOrderList[index];
            const contactIndex = this.campaign.orderList.findIndex(c => c === contact);
            if (contactIndex !== -1) {
                this.campaign.orderList[contactIndex].isDelete = true;
            }
        },
        async showAddSales() {

            if (this.roleType != 3 && this.roleType != 4 && this.roleType != 999) {
                Toast("请联系和您合作的品鉴师管理动销")
                return;
            }

            this.salesDialogVisible = true;
            let presult = await GetSystemProduct({
                current: 1,
                pageSize: 100,
                sorterType: 0,
                filterData: {
                    sourceId: 2,
                }
            });
            if (presult.data.success) {

                this.orderWineList = presult.data.data;
            }

        },
        addSales() {
            // 在这里处理确认动销的逻辑
            // 可以通过this.campaign.customerList获取终端店列表及其销售信息
            // 可以将数据发送到后端进行处理


            const selectedWines = this.orderWineList.filter((wine) => wine.count > 0);
            selectedWines.forEach((wine) => {
                // const newItem = {
                //     product: wine.name,
                //     specs: wine.specs,
                //     productPrice: wine.price, // 从接口获取的价格
                //     count: wine.count,
                //     isDelete: false,
                // };
                // this.campaign.wineList.push(newItem);
                this.newSale.product = wine.name;
                this.newSale.productPrice = wine.price;
                this.newSale.specs = wine.specs;
                this.newSale.count = wine.count;
                this.newSale.amount = wine.count * wine.price;


                const newSale = { ...this.newSale, isDelete: false };
                this.campaign.orderList.push(newSale);
            });




            this.salesDialogVisible = false;
            this.newSale = {
                typeId: 1,
                product: "",
                productPrice: 0,
                specs: "箱",
                amount: 0,
                count: 0,
            }
        },

        getCustemerText() {

            const resultString = this.filteredCustomerList.map((item, index) => {
                return `
    终端${index + 1}:${item.name}  类型${this.getCustemerType(item.typeId)}
    终端人数:${item.count}
    朋友数:${item.customerCount} 
    朋友数类型:${item.remark== undefined ? '':item.remark} 
    `;
            }).join('');

            return resultString;

        },
        getWineText() {
            const resultString = this.filteredWineList.map((item, index) => {
                return `
    ${index + 1}、${item.product}(${item.specs}) * ${item.count}
    `;
            }).join('');

            return resultString;
        },
        getOrederText() {
            const resultString = this.filteredOrderList.map((item) => {
                return `
    ${this.getOrderType(item.typeId)}  ${item.customerName == undefined ? '' : `终端 ${item.customerName}`}  
    ${item.product}(${item.specs}) * ${item.count} 
    `;
            }).join('');

            return resultString;
        },
        getRemark() {
            var resultString = ""
            if (this.roleType != 3) {
                resultString += `
${this.campaign.remark}
`
            }
            if (this.roleType != 2) {
                resultString +=
                    `
${this.campaignremark2}
`
            }
            return resultString;
        },
        getShareUrl() {
            if (this.campaign.id == "") {
                return `https://km.bjznpz.com/#/dealer/dealercampaign?para=${this.dealer.id}`;
            }
            else {
                return `https://km.bjznpz.com/#/dealer/campaigndetail?id=${this.campaign.id}`;
            }

        },

        copyToClipboard() {

            //var ctext=this.getCustemerText()
            var text = `经销商：${this.dealer.name}
所属部门：${this.dealer.center}${this.dealer.department}
活动日期：${this.campaign.date}
活动地址：${this.campaign.address}
品鉴版本：${this.campaign.version  == undefined ? '' : this.campaign.version}
销售人员：${this.campaign.mainSale == undefined ? '' : this.campaign.mainSale}
品鉴师：${this.campaign.helpSale  == undefined ? '' : this.campaign.helpSale}
品鉴类型：${this.campaign.goal== undefined ? '':this.campaign.goal}

参与总人数：${this.getComeCount()}
终端情况：${this.getCustemerText()}
活动总费用：${this.getTotalCost}
品鉴用酒：${this.getWineText}
动销：${this.getOrederText()}

总结：${this.getRemark()}
详细信息KM地址：${this.getShareUrl()}`;

            // 创建一个临时 textarea 元素
            const textarea = document.createElement('textarea');

            // 设置 textarea 的值为要复制的文本
            textarea.value = text;

            // 将 textarea 添加到文档中
            document.body.appendChild(textarea);

            // 选择文本
            textarea.select();

            try {
                // 尝试执行复制操作
                const successful = document.execCommand('copy');
                const message = successful ? '复制成功！' : '复制失败，您的手机浏览器不支持复制。';
                Toast(message)
            } catch (err) {
                Toast("复制失败,您的手机浏览器不支持复制！")
                console.error('复制失败:', err);
            }

            // 删除临时 textarea 元素
            document.body.removeChild(textarea);
        },
        // copyToClipboard2() {
        //     var text = "我是要复制的内容";
        //     // 使用 Clipboard API 将文本复制到粘贴板
        //     navigator.clipboard.writeText(text)
        //         .then(() => {
        //             console.log('复制成功！');
        //         })
        //         .catch((err) => {
        //             console.error('复制失败:', err);
        //         });
        // },
    },
};
</script>
  
<style lang="less" scoped>
.loading {
    text-align: center;
}

.container {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: #f7f8fa;

    /deep/.van-radio {
        height: 29px;
    }

    /deep/.readonly {
        background-color: #c9c5c5;
    }


    //padding: 0px 10px 20px 10px;
}

.van-cell-group--inset {
    margin: 0 16px 0px 16px;
    overflow: hidden;
    border-radius: 8px;
}

.van-doc-demo-block__title {
    margin: 0;
    padding: 32px 16px 16px;
    color: rgba(69, 90, 100, 0.6);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.van-doc-demo-block__warnning {
    margin: 0;
    padding: 16px 16px 16px;
    color: rgb(235 60 17);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    background-color: #d3d8e3;
}

.contact-wrapper {
    margin-bottom: 10px;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}


.contact-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.delete-icon {
    margin-left: 10px;
    font-size: 20px;
    color: #999;
    cursor: pointer;
}

table,
td,
th {
    border: 1px solid #ddd;
    text-align: left;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th {
    padding: 5px;
}

td {
    padding: 5px;
}
</style>